<template>
  <div>
    <van-tabs v-model="akId" @click="onClick">
      <van-tab title="党史教育" name="1">
        <div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in lists" :key="item.index">
            <router-link :to="'/partymember/history/activity/' + item.AId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Theme }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="党史故事一百讲" name="10">
        <div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in lists" :key="item.index">
            <router-link :to="'/partymember/history/activity/' + item.AId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Theme }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <van-tab title="我为群众办实事" name="2">
        <div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in list" :key="item.index">
            <router-link :to="'/partymember/history/' + item.RIId">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.ThematicUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Summary }}</span>
                  <br />
                  <span class="centertime">{{ item.IssTime }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab>
      <!-- <van-tab title="党建大事件" name="3"> 
        <van-steps direction="vertical" active-color="red" inactive-color="red" active="99999">
        <van-step v-for="item in Steps" :key="item">
          <h3>{{item.Time}}</h3>
          <p>{{item.Title}}</p>
          <p style="color:#333">{{item.Content}}</p>
        </van-step>
      </van-steps>
      </van-tab> -->
    </van-tabs>
  </div>
</template>

<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetPartyInfoPage, WeGetMyActivityPage,WxGetDateAxisList } from "@/api/RealInfo";
export default {
  data() {
    return {
      akId: 2, //tab栏
      list: [], //办实事列表
      lists: [], //党史学习列表
      Steps:[], //党建大事件
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      WeGetPartyInfoPage({ riKind: 5 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].ThematicUrl == "") {
                  this.list[i].ThematicUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    getLists(row) {
      WeGetMyActivityPage({ akId: row })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              console.log(this.lists);
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImgUrl == "") {
                  this.lists[i].AImgUrl =
                    "https://tkdlapi.zhsq.cloud//upload/images/2021/8/23914224-be5.jpg";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    AxisList(){
      WxGetDateAxisList().then((res)=>{
        this.Steps = res.data.data;
      })
    },
    // tan点击切换事件
    onClick(e) {
      if(e == 10){
        this.akId = e;
        this.getLists(11);
      }else{
         this.akId = e;
         this.getLists(-1);
      }
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    this.getList();
    this.getLists(-1);
    this.AxisList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
